<template>
  <page title="网上投诉">
    <applyheader></applyheader>
    <applyheader :post="post"></applyheader>
    <article id="page-apply" class="mt-4 mb-4 globalcomplaint">
      <section class="container container-main pt-4 mt-f-2 box-shadow-10">
        <div class="applyCon_zzy p-4" v-if="showmes === 1">
          <a-alert
            message="温馨提示"
            description="投诉提交后，由对应的受理机构处理，您可凭投诉受理流水号和手机号码查询您的投诉处理情况，登录用户可到“个人中心 - 我的投诉”中查询."
            type="warning"
            show-icon
            class="mb-4 mt-3 m-auto"
          />
            <!--步骤-->
            <!--资料提交-->
            <a-form-model ref="ruleForm" :model="complaintInfo" :rules="rules" v-bind="layout" class="mt-5">
              <a-row type="flex" justify="space-around" align="middle">
                <a-col :span="18">
                  <a-form-model-item  label="业务类型">
                    <Collection name="service_type" :limit=1000 v-slot="{ collection }"  :query="{ parent: sert }" :filter="{ parent: '投诉' }">
                     <a-select class="wight-judge" placeholder="" @change="setpeo_type($event)" default-value="">
        <a-select-option
          class="select_item"
          v-for="item in collection"
          :key="item.item_value"
         v-model="item.item_value">
          {{ item.item_name }}
        </a-select-option>
                     </a-select>
      </Collection>
                  </a-form-model-item>
                </a-col>
                <a-col :span="18">
                  <a-form-model-item has-feedback prop="order_desc" label="投诉事项">
                    <a-textarea style="height:80px" placeholder="您输入投诉事项" v-model="complaintInfo.order_desc">
                    </a-textarea>
                  </a-form-model-item>
                </a-col>
                <a-col :span="18">
                  <a-form-model-item has-feedback prop="sponsor_name" label="姓名" >
                    <a-input placeholder="请输入您的姓名" v-model="complaintInfo.sponsor_name">
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="18">
                  <a-form-model-item has-feedback prop="sponsor_phone" label="手机号" >
                    <a-input placeholder="请输入11位手机号码" v-model="complaintInfo.sponsor_phone">
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="18">
                  <a-form-model-item has-feedback prop="sponsor_idcard_no" label="身份证号">
                    <a-input placeholder="请输入18位身份证号码" v-model="complaintInfo.sponsor_idcard_no">
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12" class="mt-4">
                  <a-button type="danger" block @click="onClick">
                    提交
                  </a-button>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
           <div class="msgbox" v-if="showmes === 2">
      <a-result
    status="success"
    :title="msg.title"
    :sub-title="msg.desc"
  >
    <template #extra>
      <a-button  @click="userinfo" type="primary">
        去个人中心
      </a-button>
      <a-button  @click="sub">
        返回首页
      </a-button>
    </template>
  </a-result>
    </div>
      </section>
    </article>
  </page>
</template>
<script>
import Collection from '@/components/Collection'
import Applyheader from '@/components/base/Applyheader'
import model from '../../core/model.js'
export default {
  name: 'GlobalComplaint',
  mixins: [model],
  components: {
    Applyheader,
    Collection
  },
  activated () {
    // 登录验证
    this.goLoginTip(this.$route.path)
    var phone = this.$Cookies.get('phone')
    if (phone !== '' && phone !== undefined) {
      this.complaintInfo.sponsor_phone = phone
      this.complaintInfo.sponsor_idcard_no = this.$Cookies.get('idcard_no')
      this.complaintInfo.sponsor_name = this.$Cookies.get('user_name')
    }
  },
  methods: {
    setprovider_type (key, name) {
      this.provider_type = name
    },
    userinfo () {
      window.location.href = '/my'
    },
    sub () {
      this.$router.push('/')
    },
    setpeo_type (name) {
      this.complaintInfo.service_type = name
    },
    handleChange (value, options) {
      if (value === '') {
        this.prodata = []
        this.providerQuery.city = ''
        this.providerQuery.area = ''
        return
      }
      this.citykey = value
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.providerQuery.city = obj.district_name
    },
    areaChange (value, options) {
      if (value === '') {
        this.providerQuery.area = ''
        return true
      }
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.providerQuery.area = obj.district_name
    },
    enableSendCode (rule, value, callback) {
      const passwordReg = /^1\d{10}$/
      if (passwordReg.test(value)) {
        callback()
      } else {
        callback(new Error('格式输入错误'))
      }
    },
    onClick () {
      var that = this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          var result = that.saveData({ url: 'my-workorder/apply', data: this.complaintInfo })
          if (result) {
            that.showmes = 2
          }
        } else {
          return false
        }
      })
    }
  },
  data () {
    var msg = {
      title: '提交成功',
      desc: '您的投诉信息已经提交，请等待处理结果'
    }
    return {
      msg: msg,
      person_type: '',
      provider_type: '',
      content: '',
      type: 'pro',
      sert: '投诉',
      citykey: '',
      currentSelect: '',
      pro: 'pro',
      showmes: 1,
      commitStatus: 4,
      peo_type: '',
      post: {
        StepStatus: 1,
        service_name: '投诉'
      },
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: ''
      },
      complaintInfo: {
        business_type: '',
        order_desc: '',
        order_type: '投诉',
        platform_channel: '门户',
        platform_type: '网络',
        reserve_person: '',
        service_type: '',
        sponsor_idcard_no: '',
        sponsor_idcard_type: '身份证',
        sponsor_name: '',
        sponsor_phone: ''
      },
      rules: {
        sponsor_name: [
          { required: true, message: '请输入姓名', trigger: 'change' },
          { mix: 2, max: 6, message: '2-6个中文', trigger: 'change' }
        ],
        sponsor_idcard_no: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
          { len: 18, message: '格式错误,请检查', trigger: 'change' }
        ],
        sponsor_phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          {
            validator: this.enableSendCode,
            trigger: 'change'
          }
        ],
        order_desc: [
          { required: true, message: '请输入投诉事项', trigger: 'change' }
        ]
      },
      layout: {
        labelCol: { span: 4, offset: 0 },
        wrapperCol: { span: 16, offset: 0 }
      }
    }
  }
}
</script>
<style scoped src="../../assets/css/complaint.css"></style>
